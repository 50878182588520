.fm-container{border:solid #292929; background: #0c83df;}
.fm-button{box-sizing:border-box;margin:2px 0 2px 20px;padding:14px;width:52px;border-radius:3px; background: #e85147 !important; margin-bottom: 5px;}
.fm-button:hover{cursor:pointer;background-color:#555;-webkit-box-shadow:inset 0 0 8px rgba(0,0,0,0.3);-moz-box-shadow:inset 0 0 8px rgba(0,0,0,0.3);box-shadow:inset 0 0 8px rgba(0,0,0,0.3)}
.fm-button:hover .fm-bar{background-color:#fff}
.fm-button .fm-bar{display:block;width:24px;height:2px;border-radius:1px;background-color:#dfdfdf}
.fm-button .fm-bar+.fm-bar{margin-top:4px}
.flexmenu:before,.flexmenu:after{display:table;content:" "}
.flexmenu:after{clear:both}
.flexmenu a{text-decoration:none}
.flexmenu.fm-toggle.fm-sm{display:none}
.fm-outer{position:relative;overflow:hidden;height:100%;width:100%}
.fm-inner{position:relative;height:100%;width:100%;left:0}
.fm-inner.open{left:70%}
.fm-inner.open 
.flexmenu.fm-sm{left:0}
.flexmenu.fm-offcanvas.fm-sm{z-index:100;overflow-y:auto;overflow-x:hidden;position:fixed;top:0;left:-70%;width:70%;height:100%;-webkit-box-shadow:inset -10px 0 10px -10px rgba(0,0,0,0.3);-moz-box-shadow:inset -10px 0 10px -10px rgba(0,0,0,0.3);box-shadow:inset -10px 0 10px -10px rgba(0,0,0,0.3);background:#333}
.flexmenu.fm-lg{max-width:1170px;margin:auto;}
.flexmenu.fm-lg .navicon:after{border:4px solid transparent;border-top-color:#666;content:"";position:absolute;right:3px;top:22px}
.flexmenu.fm-lg>ul>li{float:left;}
.flexmenu.fm-lg ul{margin:0;padding:0;list-style:none}
.flexmenu.fm-lg li{position:relative;white-space:nowrap}
.flexmenu.fm-lg li ul{position:absolute;left:0;top:100%;z-index:99}
.flexmenu.fm-lg li ul .navicon:after{border:4px solid transparent;border-left-color:#666;right:10px;top:14px}
.flexmenu.fm-lg li ul ul{top:0;left:100%}
.flexmenu.fm-lg>ul>li{padding:7px 0}
.flexmenu.fm-lg a{display:block;padding:7px 25px;color:#fff;font-weight: 600;}
.flexmenu.fm-lg a:hover{color:#eee;text-decoration:none}
.flexmenu.fm-lg li ul{border:1px solid #111;border-top:3px solid #e85147;border-radius:5px;background-color:#222;}
.flexmenu.fm-lg li ul li a{color: #eee;}
.flexmenu.fm-lg li ul li a:hover{color: #e7e7e7;}
.flexmenu.fm-lg li ul li{padding:0 10px;min-width:145px}
.flexmenu.fm-lg li ul a{border-top:1px solid #000}
.flexmenu.fm-sm ul{margin:0;padding:0;list-style:none}
.flexmenu.fm-sm ul li{position:relative}
.flexmenu.fm-sm ul li a{display:block;padding:14px 15px;font-size:16px;text-shadow:0 0 1px rgba(255,255,255,0.1);color:#ccc}
.flexmenu.fm-sm ul li a:hover,.flexmenu.fm-sm ul li a.active{color:#fff;text-decoration:none}
.flexmenu.fm-sm ul li{border-top:1px solid #4d4d4d;border-bottom:1px solid #1a1a1a}
.flexmenu.fm-sm ul li a{background-color:#333}
.flexmenu.fm-sm ul li a:hover,.flexmenu.fm-sm ul li a.active{background-color:#262626}
.flexmenu.fm-sm ul ul li{border-top:1px solid #6e6e6e;border-bottom:1px solid #3b3b3b}
.flexmenu.fm-sm ul ul li a{background-color:#555;color: #e7e7e7;}
.flexmenu.fm-sm ul ul li a:hover,.flexmenu.fm-sm ul ul li a.active{background-color:#484848}
.flexmenu.fm-sm ul ul li ul li{border-top:1px solid #919191;border-bottom:1px solid #5e5e5e}
.flexmenu.fm-sm ul ul li ul li a{background-color:#777}
.flexmenu.fm-sm ul ul li ul li a:hover,.flexmenu.fm-sm ul ul li ul li a.active{background-color:#6a6a6a}
.flexmenu.fm-sm .navicon{
	position:absolute;
	top:0;right:2px;
	height:50px;
	width:50px;
	border-radius:5px;
	-webkit-box-shadow:0 0 8px rgba(0,0,0,0.3);
	-moz-box-shadow:0 0 8px rgba(0,0,0,0.3);
	box-shadow:0 0 8px rgba(0,0,0,0.3);
	background:#000 repeat-x
}
.flexmenu.fm-sm .navicon:hover{cursor:pointer}
.flexmenu.fm-sm .navicon:after{
	border:5px solid transparent;
	border-top-color:#888;
	content:"";
	position:absolute;
	right:18px;
	top:25px
}
