/***********************************
**
**	Style For BNF Express
**  Author - Kt Pau
**	themanofchin@gmail.com 
**	2015/April
**
************************************/
::selection{
	background: #e85147;
	color: #fff;
}
a{
  text-decoration: none;
  color: #e85147;
}
a:hover{
  text-decoration: underline;
  color: #c0372e;
}
body{
	font-size: 14px;
	/*font-weight: 500;*/
	font-family: 'Zawgyi-One', 'roboto', 'arial';
	color: #111;
	background: #ffffff;
	line-height: 23px;
	-webkit-font-smoothing: subpixel-antialiased;		
}
h1,h2,h3,h4 {
	font-weight: 700;
}

.user{
	background: #e85147;
	width: 100%;
	padding: 10px;
	color: #fff;
	font-size: 13px;
}
.user span a{
	color: #fff;
	text-transform: uppercase;
}
.navbar{
	padding: 12px;
	background: #222;
	border-bottom: 3px solid #e85147;
	width: 100%;
	border-radius: 0 !important;
	box-shadow: 0 2px 4px #222;
	text-transform: uppercase;
}
.navbar-brand img{
    /*position: absolute;
    width: 100%;
    left: 0;
    top: 0;*/
    text-align: center;
    margin: auto;
}
.menu ul li a i{
	color: #e85147;
}
@media screen and (min-width:768px){
    .navbar-brand-centered {
        position: absolute;
        left: 50%;
        display: block;
        width: 190px;
        height: 140px;
        border-radius: 65px;
        text-align: center;
        background-color: #222;
        padding-top: 35px !important;
        margin-top: -50px !important;
        border-bottom: 3px solid #e85147;
        box-shadow: 0 2px 4px #222;
    }
    .navbar>.container .navbar-brand-centered, 
    .navbar>.container-fluid .navbar-brand-centered {
        margin-left: -80px;
    }
}
.search-bg{
	background: rgba(255,255,255,0.6);
	-webkit-background: rgba(255,255,255,0.6);
	padding: 15px;
	border: 3px solid #fff;
}
.margin-top-50{
	margin-top: 50px;
}
.tipsy { font-size: 10px; position: absolute; padding: 5px; z-index: 100000; font-weight: 100 !important; }
.tipsy-inner { background-color: #000; color: #FFF; max-width: 200px; padding: 5px 8px 4px 8px; text-align: center; }

/* Rounded corners */
.tipsy-inner { border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px; }
  
/* Uncomment for shadow */
/*.tipsy-inner { box-shadow: 0 0 5px #000000; -webkit-box-shadow: 0 0 5px #000000; -moz-box-shadow: 0 0 5px #000000; }*/
  
.tipsy-arrow { position: absolute; width: 0; height: 0; line-height: 0; border: 5px dashed #000; }
  
/* Rules to colour arrows */
.tipsy-arrow-n { border-bottom-color: #000; }
.tipsy-arrow-s { border-top-color: #000; }
.tipsy-arrow-e { border-left-color: #000; }
.tipsy-arrow-w { border-right-color: #000; }
  
.tipsy-n .tipsy-arrow { top: 0px; left: 50%; margin-left: -5px; border-bottom-style: solid; border-top: none; border-left-color: transparent; border-right-color: transparent; }
.tipsy-nw .tipsy-arrow { top: 0; left: 10px; border-bottom-style: solid; border-top: none; border-left-color: transparent; border-right-color: transparent;}
.tipsy-ne .tipsy-arrow { top: 0; right: 10px; border-bottom-style: solid; border-top: none;  border-left-color: transparent; border-right-color: transparent;}
.tipsy-s .tipsy-arrow { bottom: 0; left: 50%; margin-left: -5px; border-top-style: solid; border-bottom: none;  border-left-color: transparent; border-right-color: transparent; }
.tipsy-sw .tipsy-arrow { bottom: 0; left: 10px; border-top-style: solid; border-bottom: none;  border-left-color: transparent; border-right-color: transparent; }
.tipsy-se .tipsy-arrow { bottom: 0; right: 10px; border-top-style: solid; border-bottom: none; border-left-color: transparent; border-right-color: transparent; }
.tipsy-e .tipsy-arrow { right: 0; top: 50%; margin-top: -5px; border-left-style: solid; border-right: none; border-top-color: transparent; border-bottom-color: transparent; }
.tipsy-w .tipsy-arrow { left: 0; top: 50%; margin-top: -5px; border-right-style: solid; border-left: none; border-top-color: transparent; border-bottom-color: transparent; }

.cd-top {
  display: inline-block;
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 40px;
  right: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  /* image replacement properties */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: rgba(232,81,71,0.9) url(../frontend/images/cd-top-arrow.svg) no-repeat center 50%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .3s 0s, visibility 0s .3s;
  -moz-transition: opacity .3s 0s, visibility 0s .3s;
  transition: opacity .3s 0s, visibility 0s .3s;
}
.cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
  -webkit-transition: opacity .3s 0s, visibility 0s 0s;
  -moz-transition: opacity .3s 0s, visibility 0s 0s;
  transition: opacity .3s 0s, visibility 0s 0s;
}
.cd-top.cd-is-visible {
  /* the button becomes visible */
  visibility: visible;
  opacity: 1;
}
.cd-top.cd-fade-out {
  /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
  opacity: .5;
}
.no-touch .cd-top:hover {
  background-color: rgba(232,81,71,0.9);
  opacity: 1;
}
@media only screen and (min-width: 768px) {
  .cd-top {
    right: 20px;
    bottom: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .cd-top {
    height: 60px;
    width: 60px;
    right: 30px;
    bottom: 30px;
  }
}
h1.hidden{
	display: none;
}
.btn-custom{
	background: #e85147;
	color: #fff;
	padding: 14px;
	text-transform: uppercase;
	-webkit-transition: background 0.5s ease-in-out;
	-moz-transition: background 0.5s ease-in-out;
	-ms-transition: background 0.5s ease-in-out;
	-o-transition: background 0.5s ease-in-out;
	transition: background 0.5s ease-in-out;
}
.btn-custom:hover{
	background: #e85147;
	color: #eee;
	padding: 14px;
	text-transform: uppercase;
	border: 1px solid #fff;
	-webkit-transition: background 0.5s ease-in-out;
	-moz-transition: background 0.5s ease-in-out;
	-ms-transition: background 0.5s ease-in-out;
	-o-transition: background 0.5s ease-in-out;
	transition: background 0.5s ease-in-out;
}
.tour-title{
	background: rgba(0,0,0,0.6);
	-webkit-background: rgba(0,0,0,0.6);
	width: 100%;
}
@media (max-width: 650px){
  .navbar-brand{
    float: right;
    margin-top: -50px !important;
  }
  .navbar-brand img.logo{
    float: right;
    margin-top: -20px !important;
    height: 60px !important;
    width: auto;
  }
}
.image-hover * {-moz-box-sizing:border-box;-webkit-box-sizing:border-box;box-sizing:border-box}

.image-hover 
{
position:relative;display:inline-block;overflow:hidden;background:#000;/*border:10px solid #fff;*/
box-shadow:0px 2px 3px rgba(0, 0, 0, 0.3);
-moz-box-shadow:0px 2px 3px rgba(0, 0, 0, 0.3);
-webkit-box-shadow:0px 2px 3px rgba(0, 0, 0, 0.3)
}

.image-hover img {display:block}
.image-hover a {text-decoration:none}
/*** effect layer-title slide-bottom hover ***/

.img-layer-title-slide-bottom-hover .layer {position:absolute;top:60%;right:0px;bottom:0px;left:0px;opacity:0;background-color:#000;text-align:center}
.img-layer-title-slide-bottom-hover .layer {opacity:0.7}

.img-layer-title-slide-bottom-hover .layer-text {position:absolute;bottom:-50px;right:0px;left:0px;padding:8px 15px;background:#fff;color:#000;font-size:16px}
.img-layer-title-slide-bottom-hover .layer-text {position:absolute;bottom:0px}

.img-layer-title-slide-bottom-hover .layer, .img-layer-title-slide-bottom-hover .layer-text
{
transition:all .4s ease-in-out;
-webkit-transition:all .4s ease-in-out;
-moz-transition:all .4s ease-in-out;
-ms-transition:all .4s ease-in-out;
-o-transition:all .4s ease-in-out;
}

.img-layer-title-slide-bottom-hover .layer, .img-layer-title-slide-bottom-hover:hover .layer-text
{
transition:all .2s ease-in-out;
-webkit-transition:all .2s ease-in-out;
-moz-transition:all .2s ease-in-out;
-ms-transition:all .2s ease-in-out;
-o-transition:all .2s ease-in-out;
}
.layer{
  color: #fff !important;
}
.bg-white-tranparent{
  padding-top: 20px;
  padding-bottom: 20px;
  background: -moz-linear-gradient(top,  rgba(255,255,255,0) 7%, rgba(252,252,252,0) 36%, rgba(252,252,252,1) 37%, rgba(245,245,245,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(7%,rgba(255,255,255,0)), color-stop(36%,rgba(252,252,252,0)), color-stop(37%,rgba(252,252,252,1)), color-stop(100%,rgba(245,245,245,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 7%,rgba(252,252,252,0) 36%,rgba(252,252,252,1) 37%,rgba(245,245,245,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  rgba(255,255,255,0) 7%,rgba(252,252,252,0) 36%,rgba(252,252,252,1) 37%,rgba(245,245,245,1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  rgba(255,255,255,0) 7%,rgba(252,252,252,0) 36%,rgba(252,252,252,1) 37%,rgba(245,245,245,1) 100%); /* IE10+ */
  background: linear-gradient(to bottom,  rgba(255,255,255,0) 7%,rgba(252,252,252,0) 36%,rgba(252,252,252,1) 37%,rgba(245,245,245,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f5f5f5',GradientType=0 ); /* IE6-9 */
}
.bg-content-01{
  background: rgba(0,0,0,0.8);
  border: 2px solid #222;
  border-radius: 6px;
  padding: 15px;
  margin-top: 20px;
  color: #fff;
}
a.link-white{
  color: #fff;
  font-size: 12px;
}
a.link-white:hover{
  color: #eee;
}
.bg-e9e9e9{
  background: #e9e9e9;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}
.title-color{
  color: #c52323;
}
.padding{
  padding: 15px;
}
.bus-route-bg{
  background: #c52323;
  color: #fff;
  padding-top: 15px;
  box-shadow: 0 2px 3px #111;
}
.bg-white{
  background: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}
hr.style-hr {
  margin: 8px !important;
  border: 0;
  height: 1px;
  background-image: -webkit-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0));
  background-image:    -moz-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0));
  background-image:     -ms-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0));
  background-image:      -o-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0));
}
ul.bus-route-list{
  list-style: none;
  padding: 15px;
}
ul.bus-route-list li{
  text-align: left !important; 
}
ul.bus-route-list li a{
  color: #fff;
  text-transform: uppercase;
}
.city-bg{
  background: rgba(11,11,11,0.6);
  width: 100%;
}
.margin{
  margin: 20px;
}
.padding{
  padding: 20px;
}
ul.why-us{
  list-style: none;
}
ul.why-us li{
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 50px;
}
.white{
  color: #fff;
}
.bg-hotel{
  background: rgba(197,35,35,0.5);
  padding-top: 45px;
  padding-bottom: 65px;
}
#owl-demo .item{
    margin: 0 10px !important;
    color: #222;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
}
.customNavigation{
  width: 100%;
  margin-top: -15px !important;
}
.customNavigation a{
  background: #c52323;
  padding: 5px 12px;
  border-radius: 12px;
  color: #fff;
  font-weight: 500;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.customNavigation a:hover{
  color: #111;
}
.customNavigation a.next{
  float: right;
}
footer{
  background: #222;
  border-top: 5px solid #c52323;
  padding-top: 15px;
  color: #fff;
}
.padding-logo{
  padding-top: 65px;
}
ul.payment{
  list-style: none;
}
ul.payment li{
  float: left;
  margin-right: 5px;
}
ul.quick-link{
  list-style: none;
}
ul.quick-link li{
  padding: 5px;
}
ul.quick-link li a{
  text-decoration: none;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
}
ul.quick-link li a:hover{
  text-decoration: underline;
  font-weight: 500;
  color: #fff;
}
.powered{
  background: #111;
  width: 100%;
  padding: 25px 0;
}
.powered p{
  margin: 0;
  padding: 0;
}
.item a img{
  width: 100% !important;
}
.margin-bottom{
  margin-bottom: 25px;
}

.form-control-search{
  border-radius: 0 !important;
  border: 4px solid #000;
  border-right: none;
  /*height: 50px;*/
  padding: 6px 12px;
  width: 100%;
  transition-duration: 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    margin: 0 !important;
}
.form-control-search:focus{
  border-right: none !important;
  border-radius: 0 !important;
  border: 4px solid #000;
  /*height: 50px;*/
  padding: 6px 12px;
  width: 100%;
  background: #E85147;
  color: #fff;
}
.input-group-btn{
  /*box-shadow:inset 0 1px 6px 1px rgba(1,1,1,0.5);*/
  border: 4px solid #000;
  border-radius: 0 !important;
  border-left: none !important;
  background: #000 !important;
}

.btn-custom:hover{
  color: #eee;
  -webkit-box-shadow:inset 0 1px 6px 1px rgba(1,1,1,0.5);
  box-shadow:inset 0 1px 6px 1px rgba(1,1,1,0.5);
  transition-duration: 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
    -moz-transition: all 0.5s ease-in;
    -o-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;
}

.dropdown-menu{
  /*background: transparent !important;*/
  box-shadow: none !important;
  border: none;
  /*width: 260px;*/
}
.track_search{
  background: transparent !important;
  box-shadow: none !important;
  border: none;
  width: 260px;
}
p.note-text-bg{
  background: #fff;
  padding: 10px;
  font-style: italic;
  text-align: left;
}
.style-hr-2 {
    padding: 0;
    border: none;
    border-top: medium double #333;
    color: #333;
    text-align: center;
}
.style-hr-3 {
    border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
}
.style-hr-2:after {
    content: "§";
    display: inline-block;
    position: relative;
    top: -0.7em;
    font-size: 1.5em;
    padding: 0 0.25em;
    background: white;
}
.margin-top{
  margin-top: 15px;
}
.border-bottom{
  border-bottom: 1px dashed #131313;
  padding-bottom: 10px;
  margin-bottom: 10px;
} 
.language{
  background: #e85147;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 15%;
  border-radius: 10px 0 0 10px;
  z-index: 9999999;
}