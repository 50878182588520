@font-face{
                font-family:"Zawgyi-One";
                src:local("Zawgyi-One"),url('../fonts/zawgyione.eot');
                src:local("Zawgyi-One"),url('../fonts/zawgyione.eot#iefix') format("embedded-opentype"),
                    url('../fonts/zawgyione.woff') format("woff"),
                    url('../fonts/zawgyione.ttf') format("truetype"),
                    url('../fonts/zawgyione.svg#zawgyi-oneregular') format("svg");
}
@font-face {
    font-family: 'roboto';
    src: url('../fonts/roboto-condensed-webfont.eot');
    src: url('../fonts/roboto-condensed-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-condensed-webfont.woff') format('woff'),
         url('../fonts/roboto-condensed-webfont.ttf') format('truetype'),
     url('../fonts/roboto-condensed-webfont.svg#bebas_neueregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'bebas_neueregular';
    src: url('../fonts/bebasneue-webfont.eot');
    src: url('../fonts/bebasneue-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bebasneue-webfont.woff') format('woff'),
         url('../fonts/bebasneue-webfont.ttf') format('truetype'),
		 url('../fonts/bebasneue-webfont.svg#bebas_neueregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/opensans-regular-webfont.eot');
    src: url('../fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),	
         url('../fonts/opensans-regular-webfont.woff') format('woff'),
         url('../fonts/opensans-regular-webfont.ttf') format('truetype'),
		 url('../fonts/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/opensans-semibold-webfont.eot');
    src: url('../fonts/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-semibold-webfont.woff') format('woff'),
         url('../fonts/opensans-semibold-webfont.ttf') format('truetype'),
		 url('../fonts/opensans-semibold-webfont.svg#open_sanssemibold') format('svg');
    font-weight: bold;
    font-style: normal;
}
@font-face{
	font-family:'Glyphicons Halflings';
	src:url('../fonts/glyphicons-halflings-regular.eot');
	src:url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/glyphicons-halflings-regular.woff') format('woff'),
		url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'),
		url('../fonts/glyphicons-halflings-regular.svg#glyphicons-halflingsregular') format('svg');
}
@font-face {
  font-family: 'FontAwesome';
  src: url('../fonts/fontawesome-webfont.eot?v=4.0.3');
  src: url('../fonts/fontawesome-webfont.eot?#iefix&v=4.0.3') format('embedded-opentype'), url('../fonts/fontawesome-webfont.woff?v=4.0.3') format('woff'), url('../fonts/fontawesome-webfont.ttf?v=4.0.3') format('truetype'), url('../fonts/fontawesome-webfont.svg?v=4.0.3#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
    font-family: 'bebas_neueregular';
    src: url('../fonts/bebasneue-webfont.svg#bebas_neueregular') format('svg');
    src: url('../fonts/bebasneue-webfont.eot'),
         url('../fonts/bebasneue-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bebasneue-webfont.woff') format('woff'),
         url('../fonts/bebasneue-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/opensans-regular-webfont.svg#open_sansregular') format('svg');
    src: url('../fonts/opensans-regular-webfont.eot'),
         url('../fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),   
         url('../fonts/opensans-regular-webfont.woff') format('woff'),
         url('../fonts/opensans-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/opensans-semibold-webfont.svg#open_sanssemibold') format('svg');
    src: url('../fonts/opensans-semibold-webfont.eot'),
         url('../fonts/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-semibold-webfont.woff') format('woff'),
         url('../fonts/opensans-semibold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
  font-family: 'FontAwesome';
  src: url('../fonts/fontawesome-webfont.svg?v=4.0.3#fontawesomeregular') format('svg');
  src: url('../fonts/fontawesome-webfont.eot?v=4.0.3'), 
       url('../fonts/fontawesome-webfont.eot?#iefix&v=4.0.3') format('embedded-opentype'), 
       url('../fonts/fontawesome-webfont.woff?v=4.0.3') format('woff'), 
       url('../fonts/fontawesome-webfont.ttf?v=4.0.3') format('truetype') ;
  font-weight: normal;
  font-style: normal;
}
@font-face{
    font-family:'Glyphicons Halflings';
    src:url('../fonts/glyphicons-halflings-regular.svg#glyphicons-halflingsregular') format('svg');
    src:url('../fonts/glyphicons-halflings-regular.eot'), 
        url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/glyphicons-halflings-regular.woff') format('woff'),
        url('../fonts/glyphicons-halflings-regular.ttf') format('truetype');
}
